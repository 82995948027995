<template>
  <!---　ユーザー情報 -->
  <div>
    <CRow>
      <CCol lg="12">
        <transition name="fade">
          <CCard v-if="show">
            <CCardHeader>
<!--              <CIcon name="cil-pencil"/>-->
              <span class="header-title">パスワード変更</span>
            </CCardHeader>
            <CCollapse :show="formCollapsed">
              <CCardBody>
                <CInput label="現在のパスワード"
                        placeholder="現在のパスワードを入力してください。"
                        type="password"
                        autocomplete="password"
                        v-model="currPassword"
                />
                <CInput label="新規のパスワード"
                        placeholder="新しいパスワードを入力してください。"
                        type="password"
                        autocomplete="password"
                        v-model="password"
                />
                <CInput
                    label="新規のパスワード(確認)"
                    placeholder="確認のため、もう一度新しいパスワードを入力してください。"
                    type="password"
                    autocomplete="password"
                    v-model="passwordConfirm"
                />
                <!--@input="passwordConfirm = $event"-->
                <div class="form-actions float-right ml-5 mb-2">
                  <CButton type="submit" color="primary" @click="save">変更</CButton>
                </div>


              </CCardBody>

              <div class="mt-5">
                <Crow>
                  <CCol class="ml-2">
                    <span style="font-size: 18px">登録メールアドレス</span>
                  </CCol>
                  <CCol class="ml-2">
                    <span style="font-size: 18px">{{this.$store.state.user.email}}</span>
                  </CCol>
                </Crow>
              </div>

            </CCollapse>
          </CCard>
        </transition>
      </CCol>
    </CRow>
  </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: 'Forms',
        data() {
            return {
                currPassword: "",
                password: "",
                passwordConfirm: "",
                formCollapsed: true,
                show: true
            }
        },
        methods: {
            save() {
                if (this.currPassword.trim() == "" || this.currPassword.length < 8) {
                    this.$swal("現在のパスワードを入力してください。");
                    return;
                }
                if (this.password == "" || this.password.length < 8) {
                  this.$swal("パスワードは8桁以上32桁以下の半角英数字・記号を入力してください。");
                    return;
                }
                if (this.password != this.passwordConfirm) {
                    this.$swal("パスワードが一致しません。");
                    return;
                }

              this.$swal({
                title: "変更確認",
                text: "変更処理を行いますか？",
                buttons: true,
                dangerMode: true,
              }).then((isOk) => {
                if (isOk) {
                  let data = {currPassword: this.currPassword, newPassword: this.password}
                  this.axios.post('/api/user/password/change',data , {headers: {'Content-Type': 'application/json'}}).then(response => {

                  }).catch(error => {
                    console.log('error', error.response.data);
                  }).finally(() => {
                    console.log('finally');
                  });
                }
              });
            }
        }
    }
</script>
